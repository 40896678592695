<template>
  <div>
    <div class="up_box">
      <div class="ul">
        <div class="bg" v-for="(item, index) in img" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>
      <input id="upload" type="file" ref="file" @change="doRealUpload" />
      <div class="sc" @click="sv" v-if="img.length < 8">
        <i class="el-icon-plus"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from 'api/common'
export default {
  provide() {
    return {
      disk: this.img,
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      img: [],
      arr: [],
      bearUrl: 'https://fafs.antiplagiarize.com/',
    }
  },
  methods: {
    async doRealUpload(e) {
      let file = e.target.files
      if (!/.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(file.type)) {
        return this.$message({
          type: 'error',
          message: '上传文件必须是图片',
        })
      }
      for (const key in file) {
        if (file.hasOwnProperty.call(file, key)) {
          let form = new FormData()
          form.append('file', file[key])
          const res = await upload(form)
          if (res.code === 200) {
            this.img.push(URL.createObjectURL(file[key]))
            this.arr.push(res.data)
            this.$store.commit('GetimgList', this.arr)
          } else {
            this.$message({
              type: 'error',
              message: res.message,
            })
          }
        }
      }
      // let form = new FormData();
      // form.append("file", file);
      // const res = await upload(form);
      // if (res.code === 200) {
      //   this.img.push("https://fafs.antiplagiarize.com/" + res.data);
      //   this.arr.push(res.data);
      //   this.$store.commit("GetimgList", this.arr);
      //   console.log(this.img);
      // }
    },
    sv() {
      console.log(111)
      this.$refs.file.click()
    },
  },
}
</script>

<style lang="scss" scoped>
#upload {
  display: none;
}
.up_box {
  display: flex;
  .ul {
    display: flex;
  }
  .bg {
    width: 60px;
    height: 60px;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .sc {
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;

    border: 1px solid #e7e9ed;

    .el-icon-plus {
      color: #e7e9ed;
    }
  }
}
</style>
